import React, { ReactElement } from "react";

export default function StrategizingIcon(): ReactElement {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="500"
            height="500"
            fill="none"
            viewBox="0 0 500 500"
        >
            <path
                fill="#000"
                fillRule="evenodd"
                d="M426.743 151.857c-35.986 0-65.428-29.441-65.428-65.428 0-35.987 29.442-65.429 65.428-65.429 35.987 0 65.429 29.442 65.429 65.429s-29.442 65.428-65.429 65.428zm0-104.688c-21.591 0-39.259 17.667-39.259 39.26 0 21.592 17.667 39.259 39.259 39.259 21.593 0 39.26-17.667 39.26-39.26 0-21.592-17.667-39.259-39.26-39.259zm-92.908 333.686l37.296 37.297-18.32 18.32-37.296-37.297-37.297 37.297-18.32-18.32 37.297-37.297-37.297-37.296 18.32-18.32 37.297 37.296 37.296-37.296 18.32 18.32-37.296 37.296zm-75.899-174.04l24.864-59.541c-32.061 11.122-178.62 69.353-194.977 202.174 29.442 6.545 51.034 32.715 51.034 64.119 0 35.987-29.441 65.429-65.428 65.429C37.442 478.996 8 449.554 8 413.567c0-32.061 22.902-58.231 52.996-64.119 4.582-28.789 35.33-169.46 212.643-221.149l-56.268-24.207 9.812-24.207 94.87 41.221-40.564 96.18-23.553-10.471zM34.17 413.57c0 21.591 17.667 39.259 39.26 39.259 21.591 0 39.259-17.667 39.259-39.259 0-21.593-17.666-39.26-39.26-39.26-21.59 0-39.259 17.667-39.259 39.26zm76.552-278.072L73.425 98.201 36.13 135.498l-18.32-18.32 37.296-37.297-37.297-37.297 18.32-18.32 37.297 37.297 37.297-37.297 18.32 18.32-37.297 37.297 37.297 37.297-18.32 18.32z"
                clipRule="evenodd"
            ></path>
        </svg>
    );
}
