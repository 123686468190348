import React, { ReactElement } from "react";

export default function EmployeeIcon(): ReactElement {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="500"
            height="500"
            fill="none"
            viewBox="0 0 500 500"
        >
            <path
                fill="#000"
                d="M121.753 287.801c-26.17 0-47.457-21.297-47.457-47.458 0-26.166 21.291-47.457 47.457-47.457 26.171 0 47.458 21.297 47.458 47.457 0 26.161-21.287 47.458-47.458 47.458zm0-74.102c-14.686 0-26.644 11.953-26.644 26.644 0 14.686 11.953 26.644 26.644 26.644 14.687 0 26.645-11.952 26.645-26.644-.006-14.692-11.959-26.644-26.645-26.644zM193.951 401.262H49.542a10.402 10.402 0 01-10.406-10.406c0-45.552 37.062-82.608 82.614-82.608 45.551 0 82.608 37.062 82.608 82.608 0 5.751-4.662 10.406-10.407 10.406zm-133.12-20.818h121.832c-4.961-29.133-30.386-51.383-60.912-51.383-30.533 0-55.959 22.25-60.919 51.383h-.001z"
            ></path>
            <path
                fill="#000"
                d="M489.599 401.262H10.801A10.402 10.402 0 01.395 390.856L.39 109.733c0-5.755 4.661-10.406 10.406-10.406h478.798c5.75 0 10.406 4.656 10.406 10.406v281.123c.005 5.75-4.656 10.406-10.401 10.406zM21.201 380.444H479.2l-.006-260.31H21.195l.006 260.31z"
            ></path>
            <path
                fill="#000"
                d="M434.66 203.295H263.485a10.402 10.402 0 01-10.406-10.407c0-5.756 4.662-10.406 10.406-10.406l171.175-.005c5.75 0 10.406 4.655 10.406 10.406.006 5.756-4.656 10.412-10.406 10.412zM434.66 260.687H263.485a10.402 10.402 0 01-10.406-10.406c0-5.756 4.662-10.406 10.406-10.406H434.66c5.75 0 10.406 4.656 10.406 10.406.006 5.756-4.656 10.406-10.406 10.406z"
            ></path>
            <path
                fill="#000"
                d="M434.66 260.687H263.485a10.402 10.402 0 01-10.406-10.406c0-5.756 4.662-10.406 10.406-10.406H434.66c5.75 0 10.406 4.656 10.406 10.406.006 5.756-4.656 10.406-10.406 10.406zM434.66 318.093H263.485a10.402 10.402 0 01-10.406-10.406c0-5.756 4.662-10.406 10.406-10.406l171.175-.006c5.75 0 10.406 4.656 10.406 10.407.006 5.756-4.656 10.411-10.406 10.411z"
            ></path>
        </svg>
    );
}
