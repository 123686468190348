import React, { ReactElement } from "react";

export default function LowRateIcon(): ReactElement {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="500"
            height="500"
            fill="none"
            viewBox="0 0 500 500"
        >
            <path
                fill="#000"
                d="M116.612 54.197c-51.165 0-92.789 41.626-92.789 92.789 0 51.165 41.626 92.789 92.789 92.789 51.165 0 92.789-41.626 92.789-92.789.004-51.165-41.616-92.789-92.789-92.789zm63.255 92.789c0 34.876-28.379 63.255-63.255 63.255-34.876 0-63.248-28.379-63.248-63.255 0-34.876 28.38-63.255 63.255-63.255 34.876 0 63.248 28.376 63.248 63.255zM384.203 244.696c-51.165 0-92.789 41.63-92.789 92.789 0 51.158 41.626 92.789 92.789 92.789 51.168 0 92.798-41.626 92.798-92.789 0-51.161-41.63-92.789-92.798-92.789zm63.255 92.789c0 34.876-28.379 63.255-63.258 63.255-34.876 0-63.255-28.379-63.255-63.255 0-34.879 28.379-63.258 63.255-63.258 34.886.007 63.258 28.379 63.258 63.258zM401.582 25.794l-249.571 398.67 31.456 19.688h.048l17.526 10.977-110.906 40.514-11.962-117.454 17.526 10.977 31.504 19.688 249.57-398.572c4.333-6.892 13.34-8.959 20.183-4.677 6.84 4.333 8.956 13.34 4.625 20.183l.001.006z"
            ></path>
        </svg>
    );
}
