import React, { ReactElement } from "react";

export default function CustomerIcon(): ReactElement {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="500"
            height="500"
            fill="none"
            viewBox="0 0 500 500"
        >
            <path
                fill="#000"
                fillRule="evenodd"
                d="M176.587 34a20.624 20.624 0 0117.163 9.181l55.75 83.607h226.871c11.394 0 20.629 9.232 20.629 20.619v298.975c0 11.044-8.686 20.059-19.598 20.592l-1.03.026H22.629C11.235 467 2 457.768 2 446.381V54.619C2 43.233 11.235 34 22.63 34h153.957zm289.466 123.716H32.941v278.363h433.112V157.716zm-123.747 51.551c34.172 0 61.876 27.692 61.876 61.855 0 16.383-6.371 31.275-16.77 42.345 31.221 17.734 47.219 53.891 47.696 90.027a1.638 1.638 0 01-.803 1.424l-.839.233h-182.64c-.486 0-.911-.264-1.139-.657l-.181-.668c.383-36.251 16.386-72.579 47.696-90.422-10.395-11.008-16.766-25.899-16.766-42.282 0-34.163 27.704-61.855 61.876-61.855h-.006zm.528 123.71l-.922.005-4.884.202c-24.202 1.89-42.226 16.803-50.88 39.243l-.669 1.791h113.648l-.088-.243c-8.825-24.44-28.517-40.361-55.127-40.978l-1.078-.02zm-.528-92.782c-17.087 0-30.936 13.845-30.936 30.927 0 16.968 13.664 30.741 30.588 30.928l.663-.026-.316.031c17.087 0 30.936-13.851 30.936-30.933s-13.849-30.927-30.936-30.927h.001zM171.069 64.932H32.939v61.86h179.378l-41.248-61.86z"
                clipRule="evenodd"
            ></path>
        </svg>
    );
}
