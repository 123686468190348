import React, { ReactElement } from "react";
import AccountingIcon from "../icons/accountingIcon";
import CustomerIcon from "../icons/customerIcon";
import EmployeeIcon from "../icons/employeeIcon";
import HumanErrorsIcon from "../icons/humanErrorsIcon";
import InventoryIcon from "../icons/inventoryIcon";
import LowRateIcon from "../icons/lowRateIcon";
import PaperworkIcon from "../icons/paperworkIcon";
import ReportingIcon from "../icons/reportingIcon";
import StrategizingIcon from "../icons/strategizingIcon";

import FeatureList from "@components/shared/featureList";

const whyUsList = [
    {
        title: "Multiple Payment Options",
        description:
            "Make well-informed business decisions with valuable insights into your sales data.",
        icon: <ReportingIcon />,
    },
    {
        title: "Simple Setup & Usage",
        description:
            "Keep track of your customers and stay in touch with them using marketing tools.",
        icon: <CustomerIcon />,
    },
    {
        title: "Compatibility with Other Business Software",
        description:
            "Streamline inventory management and get insights into your logistics trends.",
        icon: <InventoryIcon />,
    },
    {
        title: "Customer Tracking",
        description: "Manage schedules, payrolls, and time clocking with ease.",
        icon: <EmployeeIcon />,
    },
    {
        title: "Analysis of Sales Data",
        description:
            "Streamline accounting processes and maintain records of transactions.",
        icon: <AccountingIcon />,
    },
    {
        title: "Improved Inventory Management",
        description:
            "Our POS systems have built-in checks for accurate data entry.",
        icon: <HumanErrorsIcon />,
    },
    {
        title: "Scheduling, Payroll, & Time Clocking",
        description:
            "Identify gaps and create new strategies based on data insights to achieve your goals.",
        icon: <StrategizingIcon />,
    },
    {
        title: "Identification of Gaps",
        description: "Automatically generate reports and get instant updates.",
        icon: <PaperworkIcon />,
    },

    {
        title: "Reduction of Paperwork",
        description:
            "Fill out the form above to receive quotes on exclusive deals and discounts.",
        icon: <LowRateIcon />,
    },
];

export default function WhyUs(): ReactElement {
    return (
        <FeatureList
            featureList={whyUsList}
            mainTitle={"Features of a POS Include:"}
            classes={{
                mainTitleClasses: "font-bold text-center lg:text-2xl text-lg",
                titleClasses: "font-bold lg:text-lg mt-3",
                innerSectionClasses: " flex flex-wrap  mt-[53px]",

                descriptionClasses:
                    "font-light text-center  mx-auto max-w-[305px] mt-[35px]",
            }}
            colors={{
                mainTitleColor: "#1A7184",
                iconColor: "#1A7184",
                descriptionColor: "#484848",
            }}
        />
    );
}
