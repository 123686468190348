import React, { ReactElement } from "react";

export default function AccountingIcon(): ReactElement {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="500"
            height="500"
            fill="none"
            viewBox="0 0 500 500"
        >
            <path
                fill="#000"
                d="M326.8 226.288v-26.055c0-6.061-4.909-10.974-10.97-10.974-6.057 0-10.97 4.913-10.97 10.974v26.059l-43.886-.004v-53.483c0-6.061-4.909-10.974-10.97-10.974-6.061 0-10.971 4.913-10.971 10.974v53.487l-43.885-.004v-26.055c0-6.061-4.914-10.974-10.971-10.974-6.061 0-10.97 4.913-10.97 10.974v26.059l-11.961-.004c-6.058 0-10.971 4.913-10.971 10.97 0 6.061 4.914 10.974 10.971 10.974h172.032c6.061 0 10.97-4.913 10.97-10.974 0-6.057-4.909-10.97-10.97-10.97H326.8z"
            ></path>
            <path
                fill="#000"
                d="M262.344 4.12h-24.688a32.916 32.916 0 00-32.911 32.914v8.227h-86.398a60.343 60.343 0 00-60.343 60.344v329.136a60.342 60.342 0 0060.343 60.344h263.309A60.347 60.347 0 00442 434.741V105.605a60.344 60.344 0 00-60.344-60.344h-86.397v-8.227A32.914 32.914 0 00262.348 4.12h-.004zM226.69 37.034h-.004c0-6.061 4.913-10.97 10.97-10.97h24.688a10.97 10.97 0 0110.971 10.97v8.227h-46.63l.005-8.227zm-37.033 30.172H310.34v27.428a10.972 10.972 0 01-10.974 10.97h-98.741c-6.061 0-10.974-4.913-10.974-10.97l.006-27.428zm230.395 38.398v329.136h.004a38.404 38.404 0 01-38.402 38.398H118.345a38.402 38.402 0 01-38.402-38.398V105.605a38.402 38.402 0 0138.402-38.398h49.369v27.428a32.912 32.912 0 0032.915 32.911h98.741a32.91 32.91 0 0032.915-32.912V67.208h49.369a38.396 38.396 0 0127.152 11.245 38.396 38.396 0 0111.25 27.153h-.004z"
            ></path>
            <path
                fill="#000"
                d="M250.002 290.028a75.426 75.426 0 00.039 150.855 75.428 75.428 0 0075.443-75.373 75.511 75.511 0 00-22.125-53.356 75.508 75.508 0 00-53.356-22.125l-.001-.001zm50.907 58.97h-37.193v-35.165a53.656 53.656 0 0137.193 35.218v-.053zm-50.907 69.997a53.488 53.488 0 01-48.707-75.281 53.498 53.498 0 0140.48-31.033v47.341c0 2.912 1.156 5.701 3.21 7.76a10.988 10.988 0 007.761 3.214h50.467a53.585 53.585 0 01-17.403 34.197 53.612 53.612 0 01-35.808 13.802z"
            ></path>
        </svg>
    );
}
