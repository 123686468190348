import React, { ReactElement } from "react";

export default function ReportingIcon(): ReactElement {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="500"
            height="500"
            fill="none"
            viewBox="0 0 500 500"
        >
            <path
                fill="#000"
                d="M453.732 195.967c-.218.95-.584 1.861-.584 2.884v226.577c0 15.509-12.613 28.133-28.122 28.133H74.863c-15.502-.003-28.125-12.624-28.125-28.133v-17.28c-4.195 1.154-8.52 1.967-13.07 1.967-4.55 0-8.876-.813-13.07-1.967v17.28c0 29.919 24.344 54.263 54.263 54.263h350.162c29.919 0 54.263-24.344 54.263-54.263l.004-226.577c0-1.023-.359-1.926-.584-2.877-7.871 3.657-17.094 3.657-24.973-.011l-.001.004zM33.668 310.709c3.48 0 6.901.359 10.24 1.056l2.83-2.559V75.263c0-15.509 12.613-28.133 28.133-28.133h350.163c15.508 0 28.121 12.613 28.121 28.133v20.596c0 2.236.715 4.242 1.706 6.089a29.162 29.162 0 0122.726 0c.991-1.843 1.706-3.846 1.706-6.089l.003-20.596c0-29.918-24.344-54.263-54.263-54.263H74.87c-29.918 0-54.263 24.345-54.263 54.263v236.87c0 .177.098.333.106.504 4.15-1.121 8.444-1.927 12.954-1.927v-.001z"
            ></path>
            <path
                fill="#000"
                d="M496.055 140.053l-20.596-20.603c-5.095-5.095-13.386-5.081-18.478 0-4.561 4.561-5.04 11.673-1.447 16.775h-35.536c-5.204-12.304-17.429-20.596-31.025-20.596-18.564 0-33.666 15.103-33.666 33.666a33.516 33.516 0 003.737 15.419l-64.797 61.847a33.624 33.624 0 00-13.418-2.787c-13.601 0-25.822 8.291-31.025 20.596l-74.399.004c-5.203-12.305-17.428-20.596-31.024-20.596-18.564 0-33.667 15.102-33.667 33.666 0 4.456.886 8.861 2.584 12.933L47.833 329.89a33.444 33.444 0 00-14.166-3.139C15.102 326.751 0 341.854 0 360.418s15.103 33.666 33.666 33.666c18.564 0 33.667-15.102 33.667-33.666 0-3.868-.642-7.624-1.92-11.202l66.547-60.497a33.704 33.704 0 0012.409 2.373c13.601 0 25.822-8.292 31.025-20.597h74.413c5.203 12.305 17.428 20.597 31.024 20.597 18.564 0 33.667-15.095 33.667-33.659 0-4.09-.755-8.161-2.203-11.964l66.978-63.937a33.482 33.482 0 009.697 1.422c13.6 0 25.821-8.291 31.024-20.596h35.536a12.943 12.943 0 00-2.381 7.526c0 3.494 1.365 6.771 3.829 9.242 4.935 4.935 13.534 4.942 18.477 0l20.597-20.596a12.986 12.986 0 003.828-9.242 12.94 12.94 0 00-3.825-9.235zm-462.76 231.64c-7.006 0-12.688-5.682-12.688-12.689s5.682-12.689 12.689-12.689 12.69 5.682 12.69 12.689c.006 7.007-5.676 12.689-12.69 12.689zM156.92 258.066c0 7.007-5.683 12.689-12.689 12.689-7.007 0-12.69-5.683-12.69-12.689 0-7.007 5.683-12.69 12.69-12.69 6.785 0 12.217 5.356 12.565 12.055l.472.007h-.464c.003.222.116.4.116.628zm124.245 12.685c-7.007 0-12.689-5.682-12.689-12.689s5.682-12.689 12.689-12.689 12.689 5.682 12.689 12.689c-.003 7.011-5.682 12.689-12.689 12.689zm108.128-108.137c-7.007 0-12.689-5.683-12.689-12.69 0-7.006 5.683-12.689 12.689-12.689 7.007 0 12.69 5.683 12.69 12.689.003 7.011-5.675 12.69-12.69 12.69z"
            ></path>
        </svg>
    );
}
