import React, { ReactElement } from "react";

export default function HumanErrorsIcon(): ReactElement {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="500"
            height="500"
            fill="none"
            viewBox="0 0 500 500"
        >
            <path
                fill="#000"
                d="M180.084 196.449c-50.129 0-91.041-40.913-91.041-91.041s40.913-91.038 91.041-91.038c50.133 0 91.041 40.909 91.041 91.041 0 50.129-40.909 91.038-91.041 91.038zm0-159.034c-37.452 0-67.991 30.54-67.991 67.991 0 37.456 30.539 67.995 67.991 67.995 37.456 0 67.995-30.539 67.995-67.991 0-37.456-30.539-67.995-67.995-67.995zM342.574 485.706c-85.278 0-154.424-69.144-154.424-154.424s69.144-154.424 154.424-154.424c85.278-.004 154.424 68.566 154.424 153.848 0 85.282-69.144 155-154.424 155zm0-286.375c-72.601 0-131.375 58.774-131.375 131.375 0 72.605 58.77 131.952 131.375 131.952 72.602 0 131.376-59.349 131.376-131.952 0-72.601-58.774-131.375-131.376-131.375zM13.56 485.706c-6.338 0-11.523-5.185-11.523-11.523v-101.99c0-77.211 62.806-140.019 140.595-140.019 6.339 0 11.523 5.185 11.523 11.523s-5.185 11.523-11.523 11.523c-64.534 0-116.97 52.436-116.97 116.971v101.99c-.58 6.338-5.764 11.523-12.103 11.523l.001.002z"
            ></path>
            <path
                fill="#000"
                d="M383.489 383.717c-2.882 0-5.764-1.153-8.067-3.456l-82.396-82.396c-4.61-4.61-4.61-11.523 0-16.134 4.61-4.61 11.523-4.61 16.134 0l82.396 82.396c4.61 4.61 4.61 11.523 0 16.134-2.303 2.307-5.185 3.456-8.067 3.456z"
            ></path>
            <path
                fill="#000"
                d="M301.091 383.717c-2.882 0-5.764-1.153-8.067-3.456-4.61-4.61-4.61-11.523 0-16.134l82.396-82.396c4.61-4.61 11.523-4.61 16.134 0 4.61 4.61 4.61 11.523 0 16.134l-82.396 82.396c-1.728 2.307-5.185 3.456-8.067 3.456z"
            ></path>
        </svg>
    );
}
