import React, { ReactElement } from "react";

export default function PaperworkIcon(): ReactElement {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="500"
            height="500"
            fill="none"
            viewBox="0 0 500 500"
        >
            <path
                fill="#000"
                d="M491.071 17.857H294.643c-4.927 0-8.929 4.002-8.929 8.929v62.5h-71.428V8.929c0-4.927-4.002-8.929-8.929-8.929H8.929C4.002 0 0 4.002 0 8.929v250c0 4.926 4.002 8.928 8.929 8.928h62.5V437.5c0 4.927 4.001 8.929 8.928 8.929h151.786v44.642c0 4.927 4.002 8.929 8.928 8.929H437.5c4.927 0 8.929-4.002 8.929-8.929V285.714h44.642c4.927 0 8.929-4.002 8.929-8.928v-250c0-4.927-4.002-8.929-8.929-8.929zM214.286 258.93V107.143h142.857v232.143H178.571v-71.429h26.786c4.927 0 8.929-4.002 8.929-8.928zM17.857 250V17.857H196.43V250H17.857zm71.429 178.571V267.857h71.428v80.357c0 4.927 4.002 8.929 8.929 8.929h98.214v71.428H89.286zm339.285 53.572H250v-35.714h26.786c4.926 0 8.928-4.002 8.928-8.929v-80.357h80.357c4.927 0 8.929-4.002 8.929-8.929v-62.5h53.571v196.429zm53.572-214.286H375V98.214c0-4.926-4.002-8.928-8.929-8.928h-62.5V35.714h178.572v232.143z"
            ></path>
            <path
                fill="#000"
                d="M35.714 26.786h125v8.929h-125v-8.929zM53.571 44.643h89.286v8.928H53.571v-8.928z"
            ></path>
        </svg>
    );
}
